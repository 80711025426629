<template>
  <div>
    <div class="menu">
      <el-menu :default-active="activeIndex" class="el-menu" mode="horizontal" @select="selectChange">
        <el-menu-item index="0">全部</el-menu-item>
        <el-menu-item index="1">待支付</el-menu-item>
        <el-menu-item index="2">打单中</el-menu-item>
        <el-menu-item index="3">已出单</el-menu-item>
        <el-menu-item index="5">取消</el-menu-item>
        <el-menu-item index="6">发货完成</el-menu-item>
        <el-menu-item index="7">已退款</el-menu-item>
      </el-menu>
    </div>
      <el-table :data="packageData.data" :header-cell-style="{background:'#fafafa', color: 'rgba(0,0,0,.85)', fontWeight: '500'}">
        <el-table-column label="序号" align="center">
          <template slot-scope="scope">
            <span>{{scope.$index+(packageData.current_page - 1) * packageData.per_page + 1}}</span>
          </template>
        </el-table-column>
        <el-table-column label="包裹id" prop="id" align="center"></el-table-column>
        <el-table-column label="快递公司" prop="deliver_name" align="center"></el-table-column>
        <el-table-column label="快递单号" width="220" prop="waybill_code" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.waybill_code }}</span><br />
            <!-- <el-button type="primary" size="mini" @click="getFastmaillist(scope.row.waybill_code)">查询物流轨迹</el-button> -->
          </template>
        </el-table-column>
        <el-table-column label="包裹号" prop="package_no" align="center"></el-table-column>
        <el-table-column label="姓名" prop="receive_name" align="center"></el-table-column>
        <el-table-column label="手机号" prop="receive_phone" align="center"></el-table-column>
        <el-table-column label="收货地址" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.receive_province + scope.row.receive_city + scope.row.receive_town + scope.row.receive_address}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="T单号" prop="order_no" align="center"></el-table-column> -->
        <el-table-column label="第三方订单号" prop="third_order_no" align="center"></el-table-column>
        <el-table-column
          label="状态"
          align="center"
        >
          <template slot-scope="scope">
            <!-- 待支付 -->
            <div v-if="scope.row.package_status === '1'" class="to-be-paid state" style="justify-content: center">
              <span></span>待支付
            </div>
            <!-- 打单中 -->
            <div v-if="scope.row.package_status === '2'" class="in-order state" style="justify-content: center">
              <span></span>打单中
            </div>
            <div v-if="scope.row.package_status === '3'" class="in-order state" style="justify-content: center">
              <span></span>已出单
            </div>
            <!-- 取消 -->
            <div v-if="scope.row.package_status === '5'" class="cancel state" style="justify-content: center">
              <span></span>取消
            </div>
            <!-- 取消 -->
            <div v-if="scope.row.package_status === '6'" class="handle state" style="justify-content: center">
              <span></span>发货完成
            </div>
            <div v-if="scope.row.package_status === '7'" class="handle state" style="justify-content: center">
              <span></span>已退款
            </div>
          </template>
        </el-table-column>
        <el-table-column label="打单结果" prop="print_message" align="center"></el-table-column>
        <el-table-column label="发货时间" align="center">
          <template slot-scope="scope"><span>{{timeConversion(scope.row.createtime)}}</span></template>
        </el-table-column>
        <el-table-column label="同步发货状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.is_sys == 0">待同步</span>
            <span v-if="scope.row.is_sys == 1">同步成功</span>
            <span v-if="scope.row.is_sys == 2">同步失败</span>
          </template>
        </el-table-column>
        <el-table-column label="同步发货状态备注" prop="sys_error_remark" align="center"></el-table-column>
<!--        <el-table-column label="问题描述"></el-table-column>-->
        <!-- <el-table-column label="操作"></el-table-column> -->
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          @current-change="currentChange"
          :page-size="packageData.per_page"
          layout="prev, pager, next"
          :current-page="packageData.current_page"
          :total="packageData.total">
        </el-pagination>
      </div>
  </div>
</template>

<script>
import { timeConversion } from 'utils/utils'
export default {
  data () {
    return {
      activeIndex: '0'
    }
  },
  props: {
    packageData: Object
  },
  computed: {
    // 使用时间戳转化函数
    timeConversion () {
      return timeConversion
    }
  },
  methods: {
    // menu激活回调
    selectChange (index) {
      this.$emit('menuChange', index)
    },
    currentChange (val) {
      this.$emit('updatePackageData', val)
    },
    getFastmaillist (val) {
      this.$emit('getFastmaillist', val)
    }
  }
}
</script>

<style scoped src="styles/views/orderAdmin/childTable.css">
</style>
