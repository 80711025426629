<template>
  <div>
    <app-main>
      <template v-slot:main-top>
        <!-- 查询 -->
        <div class="query-header">
          <el-form label-width="100px" :model="form">
            <el-row>
              <el-col :span="7">
                <el-form-item label="T单号">
                  <el-input v-model="form.order" class="input" placeholder="T单号"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="包裹id">
                  <el-input v-model="form.id" class="input" placeholder="包裹id"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="第三方单号">
                  <el-input v-model="form.third_order_no" class="input" placeholder="第三方单号"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="7">
                <el-form-item label="快递单号">
                  <el-input v-model="form.waybill_code" class="input" placeholder="快递单号"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="包裹号">
                  <el-input v-model="form.package_no" class="input" placeholder="包裹号"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="查询时间">
                  <el-date-picker
                    class="input"
                    @change="dateChange"
                    v-model="form.times"
                    format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetimerange"
                    range-separator="~"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              </el-row>
              <el-row>
              <el-col :span="7">
                <el-form-item label="收件人姓名">
                  <el-input v-model="form.receive_name" class="input" placeholder="收件人姓名"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="收件人手机号">
                  <el-input v-model="form.receive_phone" class="input" placeholder="收件人手机号"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item>
                  <el-button type="primary" size="small" @click="queryClick">查询</el-button>
                  <el-button size="small" @click="resetClick">重置</el-button>
                  <el-button type="primary" size="small" @click="exportPack">导出
                    <!-- <a class="download" :href="url+'/api/order/excel_package?id=' + form.id + '&createtime=' + form.times"><span>导出</span></a> -->
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </template>
      <template v-slot:main-content>
        <div>
          <child-table :packageData="packageData" @menuChange="menuChange" @updatePackageData="updatePackageData" @getFastmaillist="getFastmaillist"/>
         </div>
      </template>
    </app-main>
    <el-dialog
      title="物流轨迹"
      :visible.sync="dialogVisible"
      width="50%">
      <div class="wuliuWrap"><div v-html="wuliuData"></div></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AppMain from 'components/baseStructure/AppMain'
import ChildTable from './childComps/ChildTable'
import { getPackageList, exportPackage, getSelectwuliu } from 'network/api'
import { timeConversion } from '../../../utils/utils.js'

export default {
  inject: ['mainReload'],
  components: {
    AppMain,
    ChildTable
  },
  data () {
    return {
      spanIndex: 0,
      options: ['淘宝/天猫', '阿里巴巴', '拼多多', '京东', '抖音', '其他'],
      dateSelection: ['今天', '昨天', '最近7天', '最近30天'],
      form: {
        value: '', // 平台
        times: [], // 选择时间
        package_no: '', // 包裹号
        order: '', // 单号
        third_order_no: '', // 第三方单号
        id: '', // 第三方单号
        waybill_code: '', // 快递单号
        receive_name: '',
        receive_phone: ''
      },
      orderData: {},
      platform: '', // 筛选平台参数
      activeIndex: '0', // 筛选状态参数
      currentPage: '1', // 当前页
      packageData: {},
      url: this.$url,
      dialogVisible: false,
      wuliuData: ''
    }
  },
  created () {
    // 初始化订单列表
    this.form.order = this.$route.query.order_no
    this.getList()
    // 初始化子订单数量状态
  },
  methods: {
    timeConversion,
    // 导出包裹列表
    exportPack () {
      if (!this.form.times) {
        this.$message.warning('请选择时间')
        return
      }
      exportPackage({
        package_status: this.activeIndex, // 订单状态
        order_no: this.form.order, // 订单单号
        createtime: this.form.times, // 时间
        id: this.form.id, // 备注
        package_no: this.form.package_no, // 备注
        third_order_no: this.form.third_order_no, // 第三方单号
        waybill_code: this.form.waybill_code, // 快递单号
        receive_name: this.form.receive_name,
        receive_phone: this.form.receive_phone,
        page: this.currentPage // 当前页
      }).then(res => {
        // console.log(res)
        if (res.code === 0) {
          this.$message.warning(res.msg)
        } else {
          this.download(res)
        }
      })
    },
    download (data) {
      if (!data) {
        return
      }
      const BLOB = new Blob([`\ufeff${data}`], { type: 'text/plain;charset=utf-8' })
      const url = window.URL.createObjectURL(BLOB)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', '包裹列表' + timeConversion() + '.csv')
      document.body.appendChild(link)
      link.click()
    },
    // 平台改变
    selectChange (val) {
      switch (val) {
        case '淘宝/天猫':
          this.platform = '0'
          break
        case '阿里巴巴':
          this.platform = '1'
          break
        case '拼多多':
          this.platform = '3'
          break
        case '京东':
          this.platform = '2'
          break
        case '抖音':
          this.platform = '4'
          break
        case '其他':
          this.platform = '5'
          break
      }
    },
    // 清除选择平台
    selectClear () {
      this.platform = ''
    },
    // 筛选订单列表请求
    getList () {
      getPackageList({
        package_status: this.activeIndex, // 订单状态
        order_no: this.form.order, // 订单单号
        createtime: this.form.times, // 时间
        id: this.form.id, // 备注
        package_no: this.form.package_no, // 备注
        third_order_no: this.form.third_order_no, // 第三方单号
        waybill_code: this.form.waybill_code, // 快递单号
        receive_name: this.form.receive_name,
        receive_phone: this.form.receive_phone,
        page: this.currentPage // 当前页
      }).then(res => {
        this.packageData = res.data
      })
    },
    // menu改变
    menuChange (index) {
      this.activeIndex = index
      this.currentPage = '1'
      this.getList()
    },
    // 分页改变
    curChange (val) {
      this.currentPage = val
      this.getList()
    },
    // 选择时间改变
    dateChange (val) {
      this.form.times = val
      this.spanIndex = false
      // 查询
      this.getList()
    },
    // 日期点击
    dateClick (index) {
      this.spanIndex = index
      var dayStar = new Date()
      var dayEnd = new Date()
      switch (index) {
        // 今天
        case 0:
          dayStar.setTime(dayStar.getTime())
          dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
          this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getList()
          break
        // 昨天
        case 1:
          dayStar.setTime(dayStar.getTime() - 24 * 3600 * 1000)
          dayEnd.setTime(dayEnd.getTime())
          this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getList()
          break
        // 最近7天
        case 2:
          dayStar.setTime(dayStar.getTime() - 7 * 24 * 3600 * 1000)
          dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
          this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getList()
          break
        // 最近30天
        case 3:
          dayStar.setTime(dayStar.getTime() - 30 * 24 * 3600 * 1000)
          dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
          this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getList()
          break
      }
    },
    // 查询
    queryClick () {
      this.getList()
    },
    // 重置
    resetClick () {
      this.mainReload()
    },
    updatePackageData (val) {
      this.currentPage = val
      this.getList()
    },
    getFastmaillist (val) {
      getSelectwuliu({ waybillCode: val }).then(res => {
        console.log(res, 'wl')
        if (res.code === 1) {
          this.wuliuData = res.data.xiangxiwuliu
          this.dialogVisible = true
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style scoped src="styles/components/queryHeader.css">
</style>
<style>
  .wuliuWrap {
    font-size: 14px;
  }
  .wuliuWrap i{
    margin-bottom: 10px;
    font-style: normal;
    margin-top: 20px;
    font-weight: 600;
  }
</style>
